import React from "react";
import styled from "@emotion/styled";
import { css, useTheme } from "@emotion/react";
// import BackgroundImage from "gatsby-background-image";
import { BgImage as BackgroundImage } from "gbimage-bridge";
import Image from "../../components/image";

const BackgroundImageContainer = styled.div`
  padding: 4rem 1.5rem 6rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 6rem 6rem 6rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 6rem 6rem 14.5rem;
  }
`;

const ContentBox = styled.div`
  /* @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 76.875%;
  } */

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    width: 46.875%;
    margin: 0 4.296875% 0 48.828125%;
    padding: 7rem 6rem 6rem 9rem;
  }
`;

const InnerContainer = styled.div`
  max-width: 1260px;
  margin: 0 auto;
`;

const ContentTitle = styled.h3`
  margin-top: 2.5rem;
  font-family: ${props => props.theme.fonts.medium};
  font-size: 3.1rem;
  line-height: 38px;
  margin-bottom: 2rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 4rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-top: 0;
  }
`;

const ContentText = styled.p`
  margin-top: 2.5rem;
  font-family: ${props => props.theme.fonts.regular};
  font-size: 2.3rem;
  line-height: 37px;
  margin-bottom: 2.5rem;
  color: ${props => props.theme.colors.offWhite};
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 2.6rem;
    line-height: 41px;
    margin-bottom: 3.5rem;
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 5rem;
  }
`;

const IconBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  p {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: auto;
    }
  }
`;

const IconDescription = styled.p`
  font-family: ${props => props.theme.fonts.medium};
  font-size: 2.5rem;
  line-height: 32px;
  letter-spacing: 0.75px;
  width: 53.52%;
  margin-left: 3rem;
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    font-size: 2.6rem;
    line-height: 39px;
    letter-spacing: 0;
    margin-left: 1.5rem;
  }
`;

const BackgroundImageContentBox = props => {
  const theme = useTheme();
  const sources = [
    props.backgroundImageMobile.localFile.childImageSharp.gatsbyImageData,
    {
      ...props.backgroundImageDesktop.localFile.childImageSharp.gatsbyImageData,
      media: `(min-width: ${theme.breakpoints.sm})`,
    },
  ];
  return (
    <BackgroundImageContainer
      css={css`
        background-color: ${props.contentBoxBackgroundColor};
        @media (min-width: ${theme.breakpoints.lg}) {
          background-color: ${props.backgroundColor};
        }
      `}
    >
      <InnerContainer>
        <BackgroundImage
          image={sources}
          css={css`
            padding-top: 100%;
            width: 100%;

            @media (min-width: ${theme.breakpoints.sm}) {
              padding-top: 56.25%;
            }
            @media (min-width: ${theme.breakpoints.lg}) {
              padding-top: 4.140625%;
              padding-bottom: 3.984375%;
            }

            @media (min-width: ${theme.breakpoints.xl}) {
              padding-top: 14.140625%;
              padding-bottom: 13.984375%;
            }
          `}
        >
          <ContentBox
            css={css`
              background-color: ${props.contentBoxBackgroundColor};
              display: none;
              @media (min-width: ${theme.breakpoints.lg}) {
                display: block;
              }
            `}
          >
            <ContentTitle
              css={css`
                color: ${props.titleColor};
              `}
            >
              {props.title}
            </ContentTitle>
            <ContentText>{props.paragraph}</ContentText>
            <IconBox>
              <Image image={props.icon} />
              <IconDescription
                css={css`
                  color: ${props.iconDescriptorColor};
                `}
              >
                {props.iconDescriptor}
              </IconDescription>
            </IconBox>
            {/* <Image image={props.icon.localFile.childImageSharp.fluid} /> */}
          </ContentBox>
        </BackgroundImage>
        <ContentBox
          css={css`
            background-color: ${props.contentBoxBackgroundColor};
            display: block;
            @media (min-width: ${theme.breakpoints.lg}) {
              display: none;
            }
          `}
        >
          <ContentTitle
            css={css`
              color: ${props.titleColor};
            `}
          >
            {props.title}
          </ContentTitle>
          <ContentText>{props.paragraph}</ContentText>
          <IconBox>
            <Image image={props.icon} />
            <IconDescription
              css={css`
                color: ${props.iconDescriptorColor};
              `}
            >
              {props.iconDescriptor}
            </IconDescription>
          </IconBox>
          {/* <Image image={props.icon.localFile.childImageSharp.fluid} /> */}
        </ContentBox>
      </InnerContainer>
    </BackgroundImageContainer>
  );
};

export default BackgroundImageContentBox;
