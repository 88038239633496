import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../../components/Descriptor/index";
import Image from "../../components/image";
import InnerContainer from "../../components/InnerContainer";

const ImageLinksComponent = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 6rem 1.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 10rem 6rem 12rem;
  }
`;

const InnerFlexContainer = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* display: flex; */
  }
`;

const ImageInfo = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* max-width: 43rem; */
    margin-right: 4rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* max-width: 60rem; */
    margin-right: 4rem;
  }
`;

const ImageLinksTitle = styled.h2`
  margin-top: 3.5rem;
  font-family: ${props => props.theme.fonts.medium};
  font-weight: 500;
  font-size: 4rem;
  line-height: 50px;
  color: ${props => props.theme.colors.darkRed};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 6rem;
    font-size: 6.5rem;
    line-height: 78px;
  }
`;

const ImageLinksSubtitle = styled.h3`
  margin-top: 4rem;
  font-family: ${props => props.theme.fonts.regular};
  font-size: 2.3rem;
  line-height: 35px;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 3rem;
    font-size: 2.5rem;
    line-height: 39px;
  }
`;

const ImageLinksList = styled.div`
  margin-top: 4.55rem;
  margin-bottom: 3.3rem;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    /* max-width: 43rem; */
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 6rem;
    /* max-width: 60rem; */
  }
`;

const ImageLinkItem = styled.div`
  margin-bottom: 3.3rem;
  background-color: pink;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    width: 48.372840498%;
    max-width: 60rem;
  }
`;

const ImageLinkOverlay = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 1.7rem;
  padding: 17px 34px 20px 25px;
  /* position: absolute;
  top: 0;
  z-index: 1; */
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 3.1rem;
    line-height: 38px;
    padding: 29px 51px 34px 45px;
  }
`;

const ImageLinks = props => {
  return (
    <ImageLinksComponent>
      <InnerContainer>
        <InnerFlexContainer>
          <ImageInfo>
            <Descriptor
              title={props.descriptor}
              color={props.descriptorColor}
              alignment={props.underlineAlignment}
              align="left"
            />
            <ImageLinksTitle>{props.title}</ImageLinksTitle>
            <ImageLinksSubtitle>{props.subtitle}</ImageLinksSubtitle>
          </ImageInfo>
          <ImageLinksList>
            {props.imageLinks.map((item, index) => (
              <ImageLinkItem key={index}>
                <ImageLinkOverlay
                  css={css`
                    background-color: ${item.textOverlayBackgroundColor};
                    color: ${item.textOverlayColor};
                  `}
                >
                  {item.textOverlay}
                </ImageLinkOverlay>
                <Image image={item.image} />
              </ImageLinkItem>
            ))}
          </ImageLinksList>
        </InnerFlexContainer>
      </InnerContainer>
    </ImageLinksComponent>
  );
};

export default ImageLinks;
